import clsx from "clsx";
import { DropdownMenu as DropdownMenuPrimitive } from "radix-ui";

import { twx } from "../utils/twx";

export function Dropdown(props: DropdownMenuPrimitive.DropdownMenuProps) {
  return <DropdownMenuPrimitive.Root {...props} />;
}

export function DropdownTrigger(
  props: DropdownMenuPrimitive.DropdownMenuTriggerProps,
) {
  return <DropdownMenuPrimitive.Trigger {...props} />;
}

export function DropdownMenu(
  props: DropdownMenuPrimitive.DropdownMenuContentProps,
) {
  return (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content
        {...props}
        className={clsx(
          props.className,

          // Base styles
          "isolate z-50 w-max rounded-lg p-1",

          // Handle scrolling when menu won't fit in viewport
          "overflow-y-auto",

          // Popover background
          "bg-white",

          // Shadows
          "shadow-lg ring-0.5 ring-grey-30",

          // Define grid at the menu level if subgrid is supported
          "supports-[(grid-template-columns:subgrid)]:grid supports-[(grid-template-columns:subgrid)]:grid-cols-[auto_1fr_1.5rem_0.5rem_auto]",
        )}
      />
    </DropdownMenuPrimitive.Portal>
  );
}

export function DropdownItem(
  props: DropdownMenuPrimitive.DropdownMenuItemProps,
) {
  return (
    <DropdownMenuPrimitive.Item
      {...props}
      className={clsx(
        props.className,

        // Base styles
        "group cursor-default rounded-md px-3 py-1.5 focus:outline-none",

        // Text styles
        "text-left text-system-s leading-5 text-black",

        // Focus
        "data-[highlighted]:bg-grey-10 data-[highlighted]:text-black",

        // Disabled state
        "data-[disabled]:opacity-50",

        // Use subgrid when available but fallback to an explicit grid layout if not
        "col-span-full grid grid-cols-[auto_1fr_1.5rem_0.5rem_auto] items-center supports-[(grid-template-columns:subgrid)]:grid-cols-subgrid",

        // Icon
        "[&>[data-slot=icon]]:col-start-1 [&>[data-slot=icon]]:row-start-1 [&>[data-slot=icon]]:mr-2 [&>[data-slot=icon]]:size-4",
        "[&>[data-slot=icon]]:text-grey-70 [&>[data-slot=icon]]:data-[highlighted]:text-black",
        // Icon Active
        "[&>[data-slot=active]]:absolute [&>[data-slot=active]]:right-3 [&>[data-slot=active]]:size-4 [&>[data-slot=active]]:text-blue-60",
      )}
    />
  );
}

export const DropdownItemLabel = twx.div.attrs({
  "data-slot": "label",
})`col-start-2 row-start-1`;

export const DropdownItemDescription = twx.div.attrs({
  "data-slot": "description",
})`col-span-2 col-start-2 row-start-2 text-system-s leading-5 text-grey-80 text-balance`;

export function DropdownHeader({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return (
    <div
      {...props}
      className={clsx(className, "col-span-5 px-3 pb-1 pt-2.5")}
    />
  );
}

export function DropdownHeading({
  className,
  ...props
}: { className?: string } & Omit<
  DropdownMenuPrimitive.DropdownMenuLabelProps,
  "className"
>) {
  return (
    <DropdownMenuPrimitive.Label
      {...props}
      className={clsx(
        className,
        "col-span-full grid grid-cols-[1fr,auto] gap-x-12 px-3 pb-1 pt-2 text-system-s font-medium leading-5 text-grey-80",
      )}
    />
  );
}

export function DropdownGroup({
  className,
  ...props
}: DropdownMenuPrimitive.DropdownMenuGroupProps) {
  return (
    <DropdownMenuPrimitive.Group
      {...props}
      className={clsx(
        className,
        // Define grid at the section level instead of the item level if subgrid is supported
        "col-span-full supports-[(grid-template-columns:subgrid)]:grid supports-[(grid-template-columns:subgrid)]:grid-cols-[auto_1fr_1.5rem_0.5rem_auto]",
      )}
    />
  );
}

export function DropdownSeparator({
  className,
  ...props
}: DropdownMenuPrimitive.DropdownMenuSeparatorProps) {
  return (
    <DropdownMenuPrimitive.Separator
      {...props}
      className={clsx(
        className,
        "col-span-full mx-3 my-1 h-[0.5px] border-0 bg-grey-30",
      )}
    />
  );
}
