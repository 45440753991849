import { XMarkIcon } from "@heroicons/react/20/solid";
import { useForm } from "@tanstack/react-form";
import { Dialog } from "radix-ui";
import { useNavigate } from "react-router";

import { conduitAPI } from "../apis/conduit-api";
import { useOrganization } from "../context/hooks";
import { handleError } from "../utils/handleError";
import { Button } from "./Button";
import { DialogContent, DialogOverlay } from "./DialogContent";
import { FieldInfo } from "./forms";
import { IconButton } from "./IconButton";
import { Input } from "./Input";
import Spinner from "./Spinner";

export default function CreateNewKey({
  children,
  rpcKeysCount,
}: {
  children: React.ReactNode;
  rpcKeysCount: number;
}) {
  const organization = useOrganization();

  const navigate = useNavigate();

  const form = useForm({
    defaultValues: {
      name:
        rpcKeysCount === 0
          ? `${organization.name}'s API Key`
          : `${organization.name}'s API Key ${rpcKeysCount + 1}`,
    },
    async onSubmit({ value }) {
      try {
        const res = await conduitAPI.createRpcKey({
          name: value.name,
          organization: organization.organization,
        });

        if (res.error) {
          throw new Error(res.error.message);
        }

        navigate(`/nodes/${res.rpcKey?.key}`);
      } catch (err) {
        console.error(err);

        handleError(err);
      }
    },
  });

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>

      <Dialog.Portal>
        <DialogOverlay />

        <DialogContent className="max-w-[26rem] pt-5">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();

              form.handleSubmit();
            }}
            className="flex flex-col gap-5"
          >
            <div className="flex justify-between">
              <Dialog.Title className="text-system-xl">
                Create API Key
              </Dialog.Title>

              <Dialog.Close asChild>
                <IconButton>
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="size-5 text-grey-60" />
                </IconButton>
              </Dialog.Close>
            </div>

            <form.Field
              name="name"
              validators={{
                onChange: ({ value }) => {
                  if (value.trim() === "") {
                    return "Enter an API key name";
                  }

                  return undefined;
                },
              }}
              children={(field) => (
                <div className="flex-1 shrink-0 text-left">
                  <label htmlFor={field.name} className="sr-only">
                    API key name
                  </label>

                  <div className="relative">
                    <Input
                      aria-describedby={`${field.name}-message`}
                      autoCapitalize="off"
                      autoCorrect="off"
                      className="w-full"
                      placeholder={`${organization.name}'s Key`}
                      type="text"
                      test-id="KeyNameInput"
                      id={field.name}
                      name={field.name}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                      value={field.state.value}
                    />
                  </div>

                  <FieldInfo field={field} />
                </div>
              )}
            />

            <form.Subscribe
              selector={(state) => state.isSubmitting}
              children={(isSubmitting) => (
                <Button
                  type="submit"
                  className="w-full"
                  aria-disabled={isSubmitting}
                  test-id="create_key"
                  onClick={(e) => {
                    if (isSubmitting) e.preventDefault();
                  }}
                >
                  {isSubmitting ? <Spinner /> : <span>{"Create"}</span>}
                </Button>
              )}
            />
          </form>
        </DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
