import { useFlags } from "launchdarkly-react-client-sdk";

interface Flags {
  enableMetricsDebugger: boolean;
  featuredApps: string[];
  metricsChartV2: boolean;
  selfServeMultiregion: boolean;
  showSequencerChartOverride: boolean;
  appsUninstallEnabled: boolean;
  simulatedInstallationProcess: boolean;
  appAccessList: Record<
    string,
    {
      organizations: string[];
      allowMainnet: boolean;
      allowCustomGasToken: boolean;
    }
  >;
  showUnstableMetricsData: boolean;
  enableOpMainnetDeploy: boolean;
  enableOpMainnetEigenDeploy: boolean;
  enableNodesApiKeyAccessControls: boolean;
  enableAccessControlsMethods: boolean;
  enableAccountRenameSection: boolean;
  enableOrgRenameSection: boolean;
  accountAbstractionEnableTab: boolean;
}

export function useTypedFlags() {
  return useFlags<Flags>();
}
