import clsx from "clsx";
import { Avatar as AvatarPrimitive } from "radix-ui";
import { type CSSProperties, forwardRef, useMemo } from "react";

const NetworkAvatar = forwardRef<
  HTMLSpanElement,
  { network: string; logoUrl?: string; className?: string }
>(({ network, logoUrl, className = "size-8" }, ref) => {
  const gradient = useMemo(() => {
    if (network) {
      return {
        fromColor: `#${network.slice(0, 6)}`,
        toColor: `#${network.slice(-6)}`,
      };
    }

    return {
      fromColor: "#D2D2D2",
      toColor: "#C1C1C1",
    };
  }, [network]);

  return (
    <AvatarPrimitive.Root
      ref={ref}
      className={clsx(
        "inline-flex shrink-0 select-none overflow-hidden rounded-full bg-grey-10",
        className,
      )}
    >
      <AvatarPrimitive.Image
        className="size-full rounded-[inherit] object-cover"
        draggable={false}
        src={logoUrl}
        alt=""
      />

      <AvatarPrimitive.Fallback
        delayMs={logoUrl ? 200 : 0}
        className="size-full rounded-[inherit] bg-gradient-to-br from-grey-30 to-grey-40"
        style={
          {
            "--tw-gradient-from": `${gradient?.fromColor} var(--tw-gradient-from-position)`,
            "--tw-gradient-to": `${gradient?.toColor} var(--tw-gradient-to-position)`,
          } as CSSProperties
        }
      />
    </AvatarPrimitive.Root>
  );
});

NetworkAvatar.displayName = "NetworkAvatar";

export default NetworkAvatar;
