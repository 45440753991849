import { Dialog } from "radix-ui";
import { twc } from "react-twc";

import { twx } from "../utils/twx";

export const DialogOverlay = twx(
  Dialog.Overlay,
)`fixed inset-0 z-40 bg-black/10`;

export const DialogContent = twx(
  Dialog.Content,
)`fixed left-1/2 top-1/2 z-50 w-screen max-w-2xl -translate-x-1/2 -translate-y-1/2 rounded-xl border-0.5 bg-white px-6 pb-5 pt-4 shadow-modal`;

export const DialogTitle = twx(Dialog.Title)`text-system-xl`;

export const DialogDescription = twc(
  Dialog.Description,
)`text-grey-80 text-system-m`;
