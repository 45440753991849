import { isCelestia, isEigenDa } from "@conduitxyz/ts-utils";
import type { Query } from "@tanstack/react-query";
import { queryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { isBoolean, isEmpty, omit, pipe } from "remeda";

import type { GetNetworkRequest, Network } from "../apis/api-types";
import type { UpdateNetworkRequest } from "../apis/api-types";
import { conduitAPI } from "../apis/conduit-api";
import { useOrganization } from "../context/hooks";
import { queryClient } from "../lib/query/client";

export function useGetRollup(
  slugOrId: string | undefined,
  /**
   * @todo make this better
   */
  options: {
    retry?: boolean;
    refetchInterval?:
      | number
      | false
      | ((
          query: Query<
            Network,
            Error,
            Network,
            readonly ["GetNetwork", string, string | undefined]
          >,
        ) => number | false | undefined);
  } = {},
) {
  const organization = useOrganization();

  return useQuery({
    enabled: !isEmpty(organization as Record<string, never>) && !!slugOrId,
    ...options,
    queryKey: ["GetNetwork", organization.organization, slugOrId] as const,
    queryFn: ({ signal }) =>
      patchGetNetwork(
        { organization: organization.organization, network: slugOrId! },
        signal,
      ),
  });
}

export function getRollup({
  organization,
  network,
}: {
  organization: string;
  network: string;
}) {
  return queryOptions({
    queryKey: ["GetNetwork", organization, network] as const,
    queryFn: ({ signal }) => patchGetNetwork({ organization, network }, signal),
    refetchOnWindowFocus: false,
  });
}

async function patchGetNetwork(args: GetNetworkRequest, signal?: AbortSignal) {
  const resp = await conduitAPI.getNetwork(args, signal);

  const information = resp.network.information.filter(
    (el) =>
      ![
        "Settlement Layer",
        "Data Availability Layer",
        //
        // "Contract Addresses",
        //
        // "Rollup Config",
        // "Genesis JSON",
        //
        // "Core Contracts and Config",
        // "Chain Info for Running a Node",
      ].includes(el.name),
  );

  information.splice(
    2,
    0,
    {
      name: "Framework",
      value: resp.network.type,
      url: "",
      network: resp.network.network,
    },
    {
      name: "Settlement layer",
      value: resp.network.type,
      url: "",
      network: resp.network.network,
    },
    {
      name: "Data availability layer",
      value: resp.network.type,
      url: "",
      network: resp.network.network,
    },
    {
      name: "Environment",
      value: resp.network.type,
      url: "",
      network: resp.network.network,
    },
  );

  const urls = resp.network.urls.map((el) => {
    if (
      el.displayName === "AnyTrust" &&
      (isCelestia(resp.network.type) || isEigenDa(resp.network.type))
    ) {
      return { ...el, displayName: "AnyTrust (Fallback)" };
    }

    return el;
  });

  return {
    ...resp.network,
    information,
    urls,
  } as Network;
}

export function useListRollups() {
  const organization = useOrganization();

  return useQuery({
    enabled: !!organization?.organization,
    ...listNetworksQuery(organization.organization),
  });
}

export function listNetworksQuery(organization: string) {
  return queryOptions({
    queryKey: ["DeploymentsList", organization] as const,
    queryFn: async () => {
      const resp = await conduitAPI.listNetworks({
        organization: organization,
      });

      if (resp.error) {
        throw new Error(resp.error.message);
      }

      return resp.networks;
    },
  });
}

export function useUpdateRollup() {
  return useMutation<
    Network,
    Error,
    UpdateNetworkRequest & { slug: string },
    { prevData: Network }
  >({
    mutationKey: ["updateTestnet"] as const,
    mutationFn: async (arg) => {
      const resp = await conduitAPI.updateTestnet(pipe(arg, omit(["slug"])));

      return resp.updated;
    },
    // @ts-expect-error Ignore this error, comes from official docs
    onMutate: async (args) => {
      await queryClient.cancelQueries({
        queryKey: ["GetNetwork", args.organization, args.slug],
      });

      const prevData = queryClient.getQueryData<Network>([
        "GetNetwork",
        args.organization,
        args.slug,
      ]);

      if (prevData) {
        queryClient.setQueryData<Network>(
          ["GetNetwork", args.organization, args.slug],
          (old) => {
            if (args.deletionProtection) {
              return {
                ...old!,
                deletionProtection: args.deletionProtection,
              };
            }

            if (isBoolean(args.private)) {
              return {
                ...old!,
                private: args.private,
              };
            }

            if (args.externalNodes) {
              return {
                ...old!,
                externalNodesEnabled: args.externalNodes,
              };
            }

            return old;
          },
        );
      }

      return { prevData };
    },
    onError: (_err, args, context) => {
      if (context?.prevData) {
        queryClient.setQueryData(
          ["GetNetwork", args.organization, args.slug],
          context.prevData,
        );
      }
    },
    onSettled: (_data, _err, args) => {
      queryClient.invalidateQueries({
        queryKey: ["GetNetwork", args.organization, args.slug],
      });
    },
  });
}
